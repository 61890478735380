import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as QueryString from 'query-string';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Button as AntdButton, Modal as AntdModal, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassWaveform } from '@fortawesome/pro-duotone-svg-icons';
import Lottie from 'lottie-react';
import { useMediaQuery } from 'react-responsive';

import ProgressBar from './components/ProgressBar';
import {
  ACCESS_METHOD_ONLINE_SERVICE,
  ACCESS_METHOD_TELEHEALTH, ANSWER_NO, ANSWER_YES,
  DIRECT_CONNECT_FIELD,
  EXTRA_PROVIDER_COUNT_FIELD,
  FINAL_DETAILS_FIELD,
  LOCATION_FIELD,
  NDIS_PLAN_FIELD, ONLINE_SERVICE_FIELD, ONLINE_SERVICE_NO, ONLINE_SERVICE_YES,
  PAGE_FINAL_DETAILS,
  PAGE_LOCATION,
  PAGE_SERVICE,
  PAGE_SERVICE_FOR, PAGE_VERIFICATION,
  PARTICIPANT_AGE_RANGE_FIELD,
  PLAN_TYPE_AGENCY_MANAGED,
  PROVIDER_COUNT_FIELD,
  PROVIDER_SEARCH_NAME_LOADING_FIELD,
  PROVIDER_SEARCH_NAMES_FIELD,
  SERVICES_FOR_FIELD,
  SERVICES_NEEDED_FIELD,
  TELEHEALTH_FIELD, TELEHEALTH_NO, TELEHEALTH_YES
} from '../../constants';
import Card from './components/Card';
import style from 'style';
import { AuthStore, TaxonomyStore } from 'store';
import InfoBar from '../components/InfoBar';
import { ProviderAPI as NewProviderAPI, ServiceRequestAPI, useGetApi, useMutateApi } from 'providerSite/api';
import animationAsset from 'assets/dot-loader.json';
import NotAvailable from './components/NotAvailable';
import { getAccessMethodFilter, isOnlineServiceAllowed, isTelehealthAllowed } from '../utils';
import TelehealthOnlineService from './questionComponents/TelehealthOnlineService';
import * as track from 'lib/track';


const useStyles = makeStyles(theme => ({
  flowContainer: {
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px 40px 20px',
  },
  children: {
    marginTop: '30px',
  },
  directRequestContainer: {
    marginTop: '30px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  directRequestHeader: {
    width: '350px',
    textAlign: 'center',
    background: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.common.lightBorder,
    borderRadius: '12px',
    boxShadow: theme.shadow(15),
    padding: '10px',
    marginBottom: '10px',
    fontSize: '15px',
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  coloredText: {
    color: theme.palette.secondary.main,
  },
  buttonContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: ({isFinalPage}) => isFinalPage ? 'flex-end' : 'space-between',
    columnGap: ({isFinalPage}) => isFinalPage ? '38px' : '0',
  },
  infoBarContainer: {
    marginTop: 'auto',
    marginBottom: '30px',
  },
}));

const Button = styled(AntdButton)`
  width: 200px;
  height: 45px;
  border-radius: 14px;
  scroll-margin-bottom: 30px;
`;

const ProviderCountContainer = styled.div`
  .loader {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
  }
  
  .text {
    font-size: 12px; 
    color: #278f80; 
    line-height: 12px;
    
    @media (max-width: ${style.sizes.small}) {
      font-size: 9px;
      line-height: 9px;
    }
  }

  .shimmer {
    text-align: center;
    color: rgba(255, 255, 255, 0);
    background: -webkit-gradient(linear, left top, right top, from(#024134), to(#024134), color-stop(0.5, #16e0b8));
    background: -moz-gradient(linear, left top, right top, from(#024134), to(#024134), color-stop(0.5, #16e0b8));
    background: gradient(linear, left top, right top, from(#024134), to(#024134), color-stop(0.5, #16e0b8));
    -webkit-background-size: 125px 100%;
    -moz-background-size: 125px 100%;
    background-size: 225px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #222;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
    
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin: 0 auto;
    margin-bottom: 0;
    
    @media (max-width: ${style.sizes.small}) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @-moz-keyframes shimmer {
    0% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }

  @-o-keyframes shimmer {
    0% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }
`;

const InitialStepContainer = styled.div`
  margin-left: 22px;

  .icon {
    font-size: 22px;
    color: #a9ebe1;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
    @media (max-width: ${style.sizes.small}) {
      font-size: 18px;
    }
  }
  
  @media (max-width: ${style.sizes.small}) {
      margin-left: 16px;
    }
`;

const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: saturate(180%) blur(10px);
    padding-bottom: 25px;
    margin-top: 70px;
  }
`;

const ProgressbarContent = styled.div`
  @media (max-width: ${style.sizes.small}) {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const QuestionFlowContainer = (
  {
    children,
    flow,
    answers,
    setPagename,
    currentPage,
    next,
    prev,
    complete,
    progressAction,
    flowControlButtonRef,
    updateAnswer,
  }) => {

  const location = useLocation();
  const urlParams = QueryString.parse(location.search);

  const isMobile = useMediaQuery({query: `(max-width: ${style.screens.mobile})`});
  const isSmallScreen = useMediaQuery({query: '(max-width: 1350px)'});

  const [progressbarPageValues, setProgressbarPageValues] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const [completedPages, setCompletedPages] = useState(new Set([]));

  const directRequestServiceUuid = urlParams['service_offering'];

  const classes = useStyles({isFinalPage: !next, isDirectConnect: !!directRequestServiceUuid});

  const providerSearchingParams = {
    service: answers?.[SERVICES_NEEDED_FIELD] && answers?.[SERVICES_NEEDED_FIELD][0]?.name,
    serviceLocation: answers?.[LOCATION_FIELD] && answers[LOCATION_FIELD],
    ageGroup: answers?.[FINAL_DETAILS_FIELD]?.[PARTICIPANT_AGE_RANGE_FIELD] && answers?.[FINAL_DETAILS_FIELD][PARTICIPANT_AGE_RANGE_FIELD],
    ndisRegistered: answers?.[FINAL_DETAILS_FIELD]?.[NDIS_PLAN_FIELD] === PLAN_TYPE_AGENCY_MANAGED ? true : null,
    includeEmpty: true,
    accredited: true,
    accessMethod: getAccessMethodFilter(answers),
  };

  const { data: _providerSearchData, isLoading: isProviderSearchDataLoading } = useGetApi(NewProviderAPI.GET_SEARCH_LISTINGS, {
    queryParams: {
      ...providerSearchingParams,
      limit: 1,
    },
    select: data => data,
    onError: err => console.error(err),    // eslint-disable-line no-console
  });

  const providerSearchData = _providerSearchData ? _providerSearchData : {};

  const { data: _providerSearchNames, isLoading: isProviderSearchNameLoading } = useGetApi(NewProviderAPI.GET_SEARCH_LISTINGS, {
    queryParams: {
      ...providerSearchingParams,
      limit: 50,
    },
    enabled: (
      currentPage.name === PAGE_FINAL_DETAILS && currentPage.questions[0].isValid(answers?.[FINAL_DETAILS_FIELD])
      || currentPage.name === PAGE_VERIFICATION &&
      (answers?.[TELEHEALTH_FIELD] === TELEHEALTH_YES || answers?.[ONLINE_SERVICE_FIELD] === ONLINE_SERVICE_YES)
    ),
    onError: err => console.error(err),    // eslint-disable-line no-console
  });

  const providerSearchNames = _providerSearchNames ? _providerSearchNames.map(item => item.name) : [];

  const { data: _providerExtraSearchData } = useGetApi(NewProviderAPI.GET_SEARCH_LISTINGS, {
    queryParams: {
      ...providerSearchingParams,
      includeEmpty: false,
      accessMethod: isTelehealthAllowed(answers) ? ACCESS_METHOD_TELEHEALTH : ACCESS_METHOD_ONLINE_SERVICE,
      limit: 1,
    },
    enabled: isTelehealthAllowed(answers) || isOnlineServiceAllowed(answers),
    select: data => data,
    onError: err => console.error(err),    // eslint-disable-line no-console
  });

  const providerExtraSearchData = _providerExtraSearchData ? _providerExtraSearchData : {};

  const incompleteSrMutation = useMutateApi(ServiceRequestAPI.CREATE_INCOMPLETE_SERVICE_REQUEST, 'POST');

  useEffect(() => {
    setIsModalOpen(providerSearchData.total === 0);

    if (providerSearchData.total === 0)
      track.sendEvent('service request no provider found', 'click', {data: providerSearchingParams});

    updateAnswer(PROVIDER_COUNT_FIELD, providerSearchData.total);
    updateAnswer(EXTRA_PROVIDER_COUNT_FIELD, providerExtraSearchData.total);

    if (providerSearchNames && providerSearchNames.length)
      updateAnswer(PROVIDER_SEARCH_NAMES_FIELD, providerSearchNames);

    if (isTelehealthAllowed(answers) && answers[TELEHEALTH_FIELD] === null)
      updateAnswer(TELEHEALTH_FIELD, TELEHEALTH_NO);

    if (isOnlineServiceAllowed(answers) && answers[ONLINE_SERVICE_FIELD] === null)
      updateAnswer(ONLINE_SERVICE_FIELD, ONLINE_SERVICE_NO);

  }, [_providerSearchData, _providerSearchNames, _providerExtraSearchData]);

  useEffect(() => {
    updateAnswer(PROVIDER_SEARCH_NAME_LOADING_FIELD, isProviderSearchNameLoading);
  }, [isProviderSearchNameLoading]);

  useEffect(() => {
    dispatch(TaxonomyStore.refreshTaxonomy(TaxonomyStore.QUALIFIED_SERVICE_TAXONOMY));
    dispatch(AuthStore.refreshProfile());
  }, []);


  useEffect(() => {
    const validPages = flow.pages.filter(item => (
      item?.progressbar && (!item.isHidden || !item.isHidden(answers))));

    const progressValues = validPages.reduce((acc, item, index) => {
      return {...acc, [item.name]: index};
    }, {});
    setProgressbarPageValues(progressValues);
  }, [flow, answers[DIRECT_CONNECT_FIELD]]);

  useEffect(() => {
    if (currentPage?.progressbar) {
      progressAction.set(progressbarPageValues[currentPage.name]);
    } else {
      progressAction.set(Object.keys(progressbarPageValues).length);
    }

  }, [currentPage, progressbarPageValues, progressAction]);

  useEffect(() => {
    window.scrollTo({
      top: isMobile && directRequestServiceUuid && !!prev ? 480 : 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [currentPage]);

  useEffect(() => {
    setCompletedPages(prevState => {
      const newState = new Set([...prevState]);
      let isComplete = true;
      for (const question of currentPage.questions) {
        if (!question.isValid(answers[question.field])) {
          isComplete = false;
          break;
        }
      }
      if (isComplete) {
        newState.add(currentPage.name);
      } else {
        newState.delete(currentPage.name);
      }

      return newState;
    });
  }, [answers]);

  useEffect(() => {
    setCompletedPages(prevState => {
      const newState = new Set([...prevState]);

      for (const page of flow.pages) {
        for (const question of page.questions) {
          if (question.isValid(answers[question.field])) {
            newState.add(page.name);
          }
        }
      }

      return newState;
    });
  }, []);

  // if (isLoading) return <Loader />;

  const renderFlowControlButtons = () => {

    const nextButtonText = currentPage?.flowControlButtonsCustomText?.next ?
      currentPage?.flowControlButtonsCustomText?.next : 'Next';

    const backButtonText = currentPage?.flowControlButtonsCustomText?.back ?
      currentPage?.flowControlButtonsCustomText?.back : 'Back';

    return (
      <>
        {currentPage?.hideBackButton ? <>&nbsp;</> : (
          <Button
            ref={flowControlButtonRef}
            onClick={() => {
              if (!prev)
                history.push('');
              else {
                setPagename(prev.name);
              }
            }}
            style={{
              width: '100px',
              scrollMarginBottom: !!currentPage.buttonScrollMargin && currentPage.buttonScrollMargin,
            }}
          >
            {backButtonText}
          </Button>
        )}
        <Button
          onClick={()=> {
            setPagename(next.name);
            if (currentPage?.name === PAGE_SERVICE_FOR || currentPage?.name === PAGE_FINAL_DETAILS) {
              const _data = {
                location: answers?.location,
                servicesNeeded: answers?.servicesNeeded,
                servicesFor: answers?.servicesFor,
                telehealth: answers?.telehealth,
                onlineService: answers?.onlineService,
                finalDetails: answers?.finalDetails,
                sessionId: answers?._sr_session,
              };
              incompleteSrMutation.mutate(_data);
            }
          }}
          disabled={!complete}
          type="primary"
        >
          {nextButtonText}
        </Button>
      </>
    );
  };

  const serviceStepRenderer = () => {
    if (!answers?.[SERVICES_NEEDED_FIELD] || !answers[SERVICES_NEEDED_FIELD].length) {
      return (
        <ProgressbarContent>
          Service
        </ProgressbarContent>
      );
    }
    return (
      <ProgressbarContent>
        {answers[SERVICES_NEEDED_FIELD][0].name}
      </ProgressbarContent>
    );
  };

  const locationStepRenderer = () => {
    if (!answers?.[LOCATION_FIELD]) {
      return (
        <ProgressbarContent>
          Location
        </ProgressbarContent>
      );
    }
    return (
      <ProgressbarContent>
        {answers[LOCATION_FIELD]}
      </ProgressbarContent>
    );
  };

  const requirementStepRenderer = () => {
    if (!answers?.[FINAL_DETAILS_FIELD] ||
      !answers?.[FINAL_DETAILS_FIELD]?.[PARTICIPANT_AGE_RANGE_FIELD] ||
      !answers?.[FINAL_DETAILS_FIELD]?.[NDIS_PLAN_FIELD]
    ) {
      return (
        <ProgressbarContent>
          Requirements
        </ProgressbarContent>
      );
    }
    return (
      <ProgressbarContent>
        {answers[FINAL_DETAILS_FIELD][PARTICIPANT_AGE_RANGE_FIELD]}<br/>
        {!isMobile && answers[FINAL_DETAILS_FIELD][NDIS_PLAN_FIELD]}
      </ProgressbarContent>
    );
  };

  const providerCountRenderer = () => {
    const count = answers[EXTRA_PROVIDER_COUNT_FIELD];
    const field = isTelehealthAllowed(answers) ? TELEHEALTH_FIELD : ONLINE_SERVICE_FIELD;

    const content = (
      <div>
        <TelehealthOnlineService
          isTelehealthAllowed={isTelehealthAllowed(answers)}
          checked={answers[field] === ANSWER_YES}
          onChange={e => updateAnswer(field, e.target.checked ? ANSWER_YES : ANSWER_NO)}
          count={count}
        />
      </div>
    );
    return (
      <ProviderCountContainer>
        <Tooltip
          title={content}
          visible={!!count && answers[field] !== ANSWER_YES}
          placement={isSmallScreen ? 'top' : 'right'}
          align={{offset: isSmallScreen ? [0, -10] : [25, 0]}}
          color="#CCEDE9"
          overlayInnerStyle={{
            padding: '1px 1px 7px 7px',
            margin: 0,
            minHeight: 0,
            maxWidth: '180px'
          }}
        >
          <div>
            {isProviderSearchDataLoading ? (
              <div className="loader">
                <Lottie animationData={animationAsset} loop style={{height: '30px', marginTop: '-7px'}} />
              </div>
            ) : (
              <div className="shimmer">
                {providerSearchData.total ? providerSearchData.total : 0}
              </div>
            )}
            <div className="text">
              Providers Found
            </div>
          </div>
        </Tooltip>
      </ProviderCountContainer>
    );
  };

  const steps = [
    {
      node: <InitialStepContainer><FontAwesomeIcon icon={faMagnifyingGlassWaveform} className="icon" /></InitialStepContainer>,
      isComplete: null,
      onClick: null,
    },
    {
      node: serviceStepRenderer(),
      isComplete: completedPages.has(PAGE_SERVICE),
      isCurrent: currentPage.name === PAGE_SERVICE,
      onClick: () => setPagename(PAGE_SERVICE),
      bgColor: '#7FCFC4',
    },
    {
      node: locationStepRenderer(),
      isComplete: completedPages.has(PAGE_LOCATION),
      isCurrent: currentPage.name === PAGE_LOCATION,
      onClick: () => setPagename(PAGE_LOCATION),
      bgColor: '#7AE5D7',
    },
    {
      node: requirementStepRenderer(),
      isComplete: completedPages.has(PAGE_SERVICE_FOR),
      isCurrent: currentPage.name === PAGE_SERVICE_FOR || currentPage.name === PAGE_FINAL_DETAILS,
      onClick: () => setPagename(PAGE_SERVICE_FOR),
      bgColor: '#28F1D6',
    },
    {
      node: providerCountRenderer(),
      isComplete: null,
      onClick: null,
    },
  ];

  return (
    <Container maxWidth={currentPage.fullWidth ? 'lg' : 'md'} fixed disableGutters={true}>
      <div className={classes.flowContainer}>
        <div>
          {(currentPage?.progressbar || currentPage?.progressbarShowOnly) && (
            <ProgressBar steps={steps} />
          )}
        </div>
        {answers[DIRECT_CONNECT_FIELD] && !currentPage.isEndOfJourney && (
          <div className={classes.directRequestContainer}>
            <div className={classes.directRequestHeader}>
              You will be directly connected with <span style={{color: style.colors.secondary}}>{answers[DIRECT_CONNECT_FIELD]?.providerName}</span>
            </div>
            <Card
              title={answers[DIRECT_CONNECT_FIELD]?.service}
              imagePath={answers[DIRECT_CONNECT_FIELD]?.imagePath}
            />
          </div>
        )}
        <div className={classes.children}>
          {children}
        </div>
        <div className={classes.infoBarContainer}>
          {currentPage.footer && <InfoBar text={currentPage.footer} />}
        </div>
        <div className={classes.buttonContainer}>
          {next && (currentPage?.flowControlButtons && renderFlowControlButtons())}
        </div>
      </div>
      <Modal
        visible={isModalOpen}
        closable={false}
        maskStyle={{background: 'rgba(0, 0, 0, 0.06)'}}
        footer={null}
      >
        <NotAvailable
          name={answers?.[SERVICES_FOR_FIELD]?.name}
          onPrimaryButtonClick={() => setIsModalOpen(false)}
          onSecondaryButtonClick={() => history.push('/search')}
        />
      </Modal>
    </Container>
  );
};

QuestionFlowContainer.propTypes = {
  children: PropTypes.node.isRequired,
  flow: PropTypes.object.isRequired,
  answers: PropTypes.object,
  setAnswers: PropTypes.func.isRequired,
  setPagename: PropTypes.func.isRequired,
  currentPage: PropTypes.object.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  complete: PropTypes.bool,
  progressAction: PropTypes.object.isRequired,
  scrollToFlowControlButton: PropTypes.func.isRequired,
  flowControlButtonRef: PropTypes.object,
  updateAnswer: PropTypes.func,
};

export default QuestionFlowContainer;