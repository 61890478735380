import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Lottie from 'lottie-react';

import style from 'style';
import HowItWorksCard from './HowItWorksCard';
import ServiceCard from './ServiceCard';
import animationAsset from 'assets/friend-group.json';
import BlogPosts from './BlogPosts';
import ConnectPromotion from './ConnectPromotion';
import { SERVICE_FOR_TYPE_MY_CLIENT, SERVICES_FOR_FIELD } from '../../constants';
import MoreServiceButton from './MoreServiceButton';
import Promotion from './Promotion';


const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`;

const Top = styled.div`
  width: 100%;
  height: 600px;
  background-color: #ffffff;
  background: linear-gradient(135deg, #e9e9e955 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(225deg, #e9e9e9 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(315deg, #e9e9e955 25%, transparent 25%) 0px 0/ 20px 20px, linear-gradient(45deg, #e9e9e9 25%, #ffffff 25%) 0px 0/ 20px 20px;
  -webkit-mask-image: linear-gradient(to bottom,black 10%,transparent 100%);
  mask-image: linear-gradient(to bottom,black 10%,transparent 100%);
`;

const Content = styled.div`
  max-width: ${style.sizes.small};
  margin-top: -550px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  z-index: 1;
  padding: 20px;

  .lottie-container {
    margin-top: 40px;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.6));
  }
`;

const Tracking = ({ data }) => {

  const onMoreServiceClick = () => (window.open('/requestmatch', '_blank'));

  const serviceRequest = data.serviceRequests[0];

  return (
    <Container>
      <Top />
      <Content>
        <div className="lottie-container">
          <Lottie
            animationData={animationAsset}
            style={{height: '300px', marginTop: '-110px', marginBottom: '-50px'}}
          />
        </div>
        <MoreServiceButton
          onClick={onMoreServiceClick}
          style={{marginLeft: 'auto', marginBottom: '-15px'}}
        />
        <HowItWorksCard
          name={data.firstName}
          providerCount={serviceRequest.data.providerCount}
          trackingPage={true}
        />
        {data?.data?.[SERVICES_FOR_FIELD] === SERVICE_FOR_TYPE_MY_CLIENT && (
          <ConnectPromotion apiData={data}/>
        )}
        <ServiceCard data={serviceRequest} location={data.location}/>
        <Promotion
          title="Participant Webinar: What’s changing in the NDIS?"
          description="While you wait, why not learn more about the upcoming NDIS
          changes including the funded NDIS supports list changes & mandatory
          registration requirements for providers."
          button="Click here to register"
          url="https://event.webinarjam.com/register/11/o3g4qh7"
          footer="It's FREE, and you can watch the instant replay right away!"
        />
        <BlogPosts serviceCategory={serviceRequest.categoryName}/>
      </Content>
    </Container>
  );
};

Tracking.propTypes = {
  data: PropTypes.object,
};


export default Tracking;

