import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Alert as AntdAlert, Button } from 'antd';

import { useContent } from './ContentProvider';
import Markdown from './Markdown';
import Spinner from 'components/Spinner';
import * as track from '../lib/track';


const Container = styled.div`
  
`;

const Alert = styled(AntdAlert)`
  border-radius: 18px;
  
  .ant-alert-message {
    font-weight: 700; 
    font-size: 20px;
    margin-top: -3px;
  }
  
  .ant-alert-description {
    margin-left: -40px;
  }
`;

const Image = ({ node }) => {
  if (!node) return;
  return (
    <img src={node.properties.src} alt={node.properties.alt} style={{width: '100%'}} />
  );
};

Image.propTypes = {
  node: PropTypes.object,
};

const Notice = () => {
  const content = useContent('/notice/') || {};

  const renderBody = () => {

    if (!content.title) return <Spinner />;

    return (
      <>
        <Markdown content={content.body} renderers={{'img': Image}} />
        {!!content.buttonText && (
          <Button
            type="primary"
            onClick={() => {
              track.sendEvent(`Notice Button Click - ${content.title}`, 'click');
              window.open(content.buttonUrl, '_blank');
            }}
          >
            {content.buttonText}
          </Button>)}
      </>
    );
  };

  return (
    <Container>
      <Alert
        message={content.title}
        description={renderBody()}
        type="info"
        showIcon
      />
    </Container>
  );
};


export default Notice;

