import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  
  '& p': {
    max-width: 400px;
  },
  '& a': {
    font-size: 20px;
  }
  
  .promo-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 14px;
  }
  
  .text-button {
    font-weight: 700;
    font-size: 18px;
  }
`;

const Promotion = ({ title, description, button, url, footer }) => {

  return (
    <Container>
      <div className="promo-title">
        {title}
      </div>
      <p>
        {description}
      </p>
      <a className="text-button" target="_blank" rel="noopener noreferrer" href={url}>
        {button}
      </a>
      <p style={{fontSize: '13px'}}>
        {footer}
      </p>
    </Container>
  );
};

Promotion.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  footer: PropTypes.string,
};


export default Promotion;

