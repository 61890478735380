import * as track from '../lib/track';

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailTo from './MailTo';
import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import Typography from '@material-ui/core/Typography';
import config from '../config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '7rem 1rem 2rem 1rem',
    backgroundColor: theme.palette.common.nightBlue,
    [theme.breakpoints.down('sm')]: {
      padding: '4rem 1.5rem 1.5rem 1.5rem',
    },
  },
  title: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    margin: 0,
  },
  caption: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      marginBottom: '8rem',
    },
  },
  captionText: {
    width: '100%',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  list: {
    padding: '0.5rem 0 0 0',
    listStyleType: 'none',
    paddingInlineStart: 0,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: '1rem',
  },
  link: {
    textDecoration: 'none',
    fontSize: '15px',
    color: theme.palette.common.white,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconButton: {
    padding: 0,
    fontSize: '15px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    marginRight: '4px',
  },
  nuonic: {
    height: '2rem',
    marginTop: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const handleClick = (item) => {
    track.sendEvent(`clickability ${item} link`, 'click');
  };
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" fixed disableGutters={true}>
        <Grid container spacing={6}>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6" className={classes.title}>
              Services
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}><Link className={classes.link} to="/blog">Find information about the NDIS</Link></li>
              <li className={classes.listItem}><Link className={classes.link} to="/requestmatch">Connect with NDIS providers</Link></li>
              <li className={classes.listItem}><Link className={classes.link} to="/search">Browse NDIS service directory</Link></li>
            </ul>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6" className={classes.title}>
              Content
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}><Link className={classes.link} to="/blog">Blog</Link></li>
              <li className={classes.listItem}><a className={classes.link} href={config.link.faq}>FAQs</a></li>
              <li className={classes.listItem}><Link className={classes.link} to="/review">How to write a review</Link></li>
              <li className={classes.listItem}><Link className={classes.link} to="/content-guidelines">Content guidelines</Link></li>
            </ul>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6" className={classes.title}>
              Resources
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}><Link className={classes.link} to="/about">About us</Link></li>
              <li className={classes.listItem}><a className={classes.link} href={config.link.faq}>Help &amp; Support</a></li>
              <li className={classes.listItem}><Link className={classes.link} to="/contact">Contact us</Link></li>
              <li className={classes.listItem}><Link className={classes.link} to="/terms">Terms &amp; Conditions</Link></li>
              <li className={classes.listItem}><Link className={classes.link} to="/privacy">Privacy statement</Link></li>
            </ul>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6" className={classes.title}>
              Social
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <a className={classes.link} href="https://facebook.com/clickability.com.au" target="_blank" rel="noopener noreferrer" onClick={()=>handleClick('facebook')}>
                  <IconButton aria-label="facebook" className={classes.iconButton} tabIndex={-1} >
                    <FacebookIcon fontSize="small" className={classes.icon} />
                    Facebook
                  </IconButton>
                </a>
              </li>
              <li className={classes.listItem}>
                <a className={classes.link} href="https://www.instagram.com/_clickability/" target="_blank" rel="noopener noreferrer" onClick={()=>handleClick('instagram')}>
                  <IconButton aria-label="instagram" className={classes.iconButton} tabIndex={-1} >
                    <InstagramIcon fontSize="small" className={classes.icon} />
                    Instagram
                  </IconButton>
                </a>
              </li>
              <li className={classes.listItem}>
                <a className={classes.link} href="https://twitter.com/_clickability" target="_blank" rel="noopener noreferrer" onClick={()=>handleClick('twitter')}>
                  <IconButton aria-label="twitter" className={classes.iconButton} tabIndex={-1} >
                    <TwitterIcon fontSize="small" className={classes.icon} />
                    Twitter
                  </IconButton>
                </a>
              </li>
              <li className={classes.listItem}>
                <a className={classes.link} href="https://www.linkedin.com/company/clickability---rated-reviewed-reliable" target="_blank" rel="noopener noreferrer" onClick={()=>handleClick('linkedin')}>
                  <IconButton aria-label="linkedin" className={classes.iconButton} tabIndex={-1} >
                    <LinkedInIcon fontSize="small" className={classes.icon} />
                    LinkedIn
                  </IconButton>
                </a>
              </li>
              <li className={classes.listItem}>
                <MailTo className={classes.link} email={config.socials.infoEmail} onClick={()=>handleClick('email')}>
                  <IconButton aria-label="email" className={classes.iconButton} tabIndex={-1} >
                    <EmailIcon fontSize="small" className={classes.icon} />
                    Email
                  </IconButton>
                </MailTo>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" className={classes.divider}/>
          </Grid>
          <Grid item sm={12} className={classes.caption}>
            <Typography variant="caption" component={'p'} className={classes.captionText}>
              Clickability © 2019-2024. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
