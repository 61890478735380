import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Page from '../../components/Page.js';
import Container from '@material-ui/core/Container';
import ProviderLoginContainer from './components/ProviderLoginContainer';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import backgroundImg from '../../assets/login-background.svg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/Button';
import config from '../../config';
import Notice from '../../components/Notice';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2rem 1rem 5rem 1rem',
    width: '100%',
    minHeight: '56rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 300px',
    backgroundSize: 'contain',
    backgroundImage: `url(${backgroundImg})`
  },
  main: {
    paddingRight: '3rem',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  panel: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  section: {
    padding: '3rem 0 2rem 0',
  },
  spaced: {
    marginBottom: '1.5rem',
  },
  list: {
    margin: 0,
    padding: '0 0 1rem 1rem',
  },
  form: {
    width: '37rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    marginBottom: '1.5rem',
  }
}));

const LoginPage = ({isAuthenticated}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let username = query.get('username');
  username = username && username.replaceAll(' ', '+');
  const redirectTo = location.state && location.state.redirectTo ? location.state.redirectTo : '/profile';

  // We may be already logged in, or logged in via another tab (via authSync),
  // in which case we should continue with our redirect.
  useEffect(() => {
    if (isAuthenticated) {
      history.push(redirectTo);
    }
  }, [isAuthenticated, redirectTo, history]);

  return (
    <Page title="Clickability Provider Login">
      <div className={classes.root}>
        <Container maxWidth="lg" fixed disableGutters={true}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div className={classes.main}>
                <div className={classes.section}>
                  <ProviderLoginContainer
                    initialUsername={username}
                  />
                </div>
              </div>
              <div className={classes.panel}>
                <div className={classes.section}>
                  <Typography variant="h2" color="primary" className={classes.spaced}>
                    Don&apos;t have a provider account yet?
                  </Typography>
                  <Button variant="outlined" label="Provider Sign Up" className={classes.button} to="/providers/signup"/>
                  <Typography variant="body2" className={classes.spaced}>
                    Need more info? Have a look through our <a href={config.link.providerFaq}><b>Provider FAQs</b></a>.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <Notice />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(LoginPage);
