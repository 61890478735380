import * as track from 'lib/track';

import Button from 'components/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MatchingExample from 'modules/home/components/MatchingExample';
import MatchingExampleBg from 'assets/matching-example-bg.svg';
import {NAV_BAR_HEIGHT} from 'components/constants';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: `calc(${NAV_BAR_HEIGHT} + 48px) 0 48px 0`,
    marginTop: `-${NAV_BAR_HEIGHT}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    zIndex: 0,
    background: 'linear-gradient(180deg, #8DE4DC 0%, #21BFB0 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    borderRadius: '0px 0px 0px 100px',
    [theme.breakpoints.down('sm')]: {
      padding: `${NAV_BAR_HEIGHT} 16px 12px 16px`,
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    backgroundImage: `url(${MatchingExampleBg})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  stacked: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    gap: '8rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '3.15rem',
    lineHeight: '3.3rem',
    fontWeight: 700,
    color: '#050E40',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      lineHeight: '2.7rem',
    },
  },
  button: {
    backgroundColor: '#264F73',
    borderRadius: '0.75rem',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
    padding: '1rem 1.8rem',
    fontSize: '1.6rem',
    marginTop: '1rem',
    marginRight: '0px',
    '&:hover': {
      backgroundColor: '#050E40',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      padding: '1rem 1rem',
      width: '100%',
    },
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  flexContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '20px',
      rowGap: '20px',
    },
  },
  left: {
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
  },
}));


const PromoHero = () => {
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.gleamjs.io/e.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onClick = () => {
    track.sendEvent('Connect me with providers button', 'click');
    history.push('/requestmatch');
  };

  return (
    <div className={classes.root} aria-hidden="true">
      <Container maxWidth="lg" fixed disableGutters={true}>
        <div className={classes.flexContainer}>
          <Grid item md={6} sm={12}>
            <div className={classes.left}>
              <div />
              <a
                className="e-widget no-button"
                href="https://gleam.io/95Qov/clickabilitys-2025-ultimate-giveaway"
                rel="nofollow"
              >
                Clickability&apos;s Huge Giveaway
              </a>
            </div>
          </Grid>
          <Grid item md={6} sm={12} className={classes.grid}>
            <div className={classes.stacked}>
              <div className={classes.centered}>
                <Typography variant="h1" className={classes.text} gutterBottom>
                  Save time.
                </Typography>
                <Typography variant="h1" className={classes.text} gutterBottom>
                  We&apos;ll connect you to highly rated NDIS providers.
                </Typography>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  label="Connect me with providers"
                  onClick={onClick}
                />
              </div>
              <div className={classes.right}>
                <MatchingExample/>
              </div>
            </div>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default PromoHero;
