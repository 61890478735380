import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Alert, Modal as AntdModal, Switch } from 'antd';

import * as track from 'lib/track';
import PlanSummary from 'modules/auth/components/PlanSummary';
import CardPayment from 'components/braintree/CardPayment';
import SpinnerModal from 'components/SpinnerModal';
import { BillingAPI } from 'api';
import style from 'style';
import Tier from './components/Tier';
import TIERS, { TIER_ICONS, SPECIAL_TIER_NICHE_AND_REGIONAL } from './tiers';
import FreeTier from './components/FreeTier';
import providerHero from 'assets/provider-hero.png';
import { BILLED_TYPE_QUARTERLY, BILLED_TYPE_ANNUALLY } from 'lib/constants';
import CardPaymentHosted from '../braintree/CardPaymentHosted';
import SpecialTier from './components/SpecialTier';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  row-gap: 10px;
`;

const SelectedSubscriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  
  .text {
    font-size: 16px;
    line-height: 22px;
    color: #6F6C90;
  }
  
  .tier-name {
    font-weight: 700;
    font-size: 22px;
    color: ${style.colors.primary};
  }
  
  .icon-container {
    height: 82px;
    width: 82px;
    background: #ECEBFF;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const FreeTrialContainer = styled.div`
  margin-top: 25px;
  border: 1px solid #b7eb8f;
  border-radius: 14px;
  padding: 20px;
  background: #f6ffed;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .amount {
    font-size: 45px;
    line-height: 45px;
    font-weight: 700;
    color: #389e0d;
  }

  .due {
    color: #828282;
  }

  .text {
    margin-top: 10px;
    color: #353535;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  width: 100%;
  padding-right: 5px;
  justify-content: end;
  .switch {
    width: 100px;
    font-weight: 700;
    .ant-switch-inner {
      font-size: 11px;
    }
  }
  .bottom-text {
    font-size: 11px;
    text-align: center;
    margin-top: 5px;
    color: ${style.colors.palette.textGrey};
  }
  .top-text {
    font-size: 11px;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 500;
    color: ${style.colors.palette.textGrey};
  }
  
  @media (max-width: ${style.sizes.small}) {
    justify-content: center;
  }
`;

const TierContainer = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media (max-width: ${style.sizes.medium}) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 30px;
  }
  .ant-modal-body {
    padding-top: 30px;
  }
`;

const Testimonial = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  color: ${style.colors.palette.battleshipGrey};
  font-style: italic;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 14px;
`;


const Subscription = (
  {
    onComplete,
    busy,
    signupError,
    setSignupError,
    providerUuid,
    buttonText,
    allowFreeTrial=true,
    offer={},
    trackingEmail,
    showSpecialTier,
  }) => {

  const [plans, setPlans] = useState();
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [planId, setPlanId] = useState();
  const plan = plans && plans.find(({ id }) => id === planId);
  const [isLoading, setIsLoading] = useState(false);
  const [billedType, setBilledType] = useState(BILLED_TYPE_ANNUALLY);
  const [showModal, setShowModal] = useState(false);

  const [clientToken, setClientToken] = useState();
  const [customerId, setCustomerId] = useState();
  const [error, setError] = useState();

  useEffect(()=> {
    setIsLoading(true);
    BillingAPI.getPlans()
      .then(r => r.payload)
      .then(r => {
        setPlans(r.plans);
        setPlanId(r.plans[0].id);
      })
      .catch(err => console.error(err))   // eslint-disable-line no-console
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (![BILLED_TYPE_ANNUALLY, BILLED_TYPE_QUARTERLY].includes(offer.paymentPeriod))
      return;
    setBilledType(offer.paymentPeriod);
  }, [offer.paymentPeriod]);

  useEffect(() => {
    const getClientToken = async () => {
      try {
        const response = await BillingAPI.getClientToken({anonymous: false});
        setClientToken(response.payload.client_token);
        setCustomerId(response.payload.customer_id);
      } catch {
        setError('Unable to initialise payment gateway.');
      }
    };
    if (providerUuid) return;
    getClientToken();
  }, [providerUuid]);

  const handleTierClick = (btPlanId, tier) => () => {
    _handleTierClick(
      btPlanId,
      tier.subscriptionTierName,
      tier.billedType[billedType],
      tier.name,
    );
  };

  const handleSpecialTierClick = () => {
    setBilledType(BILLED_TYPE_QUARTERLY);
    _handleTierClick(
      SPECIAL_TIER_NICHE_AND_REGIONAL.btPlanId.quarterly,
      SPECIAL_TIER_NICHE_AND_REGIONAL.subscriptionTierName,
      SPECIAL_TIER_NICHE_AND_REGIONAL.billedType.quarterly,
      SPECIAL_TIER_NICHE_AND_REGIONAL.name,
    );
  };

  const _handleTierClick = (btPlanId, subscriptionTierName, billedType, tierName) => {
    setSelectedSubscription({
      subscriptionTierName: subscriptionTierName,
      btPlanId: btPlanId,
      billedType: billedType,
      tierName: tierName,
    });
    setPlanId(btPlanId);
    setShowModal(true);
    track.sendEvent(
      'signup-billing-subscribe-button-clicked',
      'click',
      {
        email: trackingEmail,
        subscriptionTier: subscriptionTierName,
      }
    );
  };

  const handleComplete = args => (
    onComplete({
      billing_plan_id: selectedSubscription.btPlanId,
      subscription_tier_name: selectedSubscription.subscriptionTierName,
      offer_id: plan?.id === offer.planId ? offer.offerId : null,
      ...args,
    })
  );

  return (
    <Container>
      <SwitchContainer>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <div className="top-text" style={{opacity: billedType !== BILLED_TYPE_ANNUALLY && 0}}>
            (Save 33%) 🔥
          </div>
          <Switch
            className="switch"
            checkedChildren="Annually"
            unCheckedChildren="Quarterly"
            checked={billedType === BILLED_TYPE_ANNUALLY}
            onChange={checked => setBilledType(checked ? BILLED_TYPE_ANNUALLY : BILLED_TYPE_QUARTERLY)}
          />
          <div className="bottom-text">
            Swap to {billedType === BILLED_TYPE_ANNUALLY ? 'Quarterly' : 'Annual'} pricing
          </div>
        </div>
      </SwitchContainer>
      {showSpecialTier && (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '10px'}}>
          <SpecialTier onClick={handleSpecialTierClick}/>
        </div>
      )}
      <TierContainer>
        {TIERS.map(item => (
          <Tier
            key={item.name}
            name={item.name}
            subTitle={item.subTitle}
            description={item.description}
            price={item.price[billedType]}
            billedPrice={item.billedPrice[billedType]}
            billedType={item.billedType[billedType]}
            icon={item.icon}
            alternate={item.alternate}
            benefits={item.benefits}
            onClick={handleTierClick(item.btPlanId[billedType], item)}
            buttonNode={buttonText && buttonText}
            discountedPrice={offer.planId === item.btPlanId[billedType] && offer.discountedAmountMonthlyExclGst}
            discountedBilledPrice={offer.planId === item.btPlanId[billedType] && offer.discountedAmountExclGst}
          />
        ))}
      </TierContainer>
      <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px'}}>
        {!showSpecialTier && <FreeTier />}
      </div>
      {signupError && <Alert message={signupError} type="error"/>}
      <Testimonial>
        <img src={providerHero} width="75px" alt="provider"/>
        <div style={{maxWidth: '400px'}}>
          &quot;We love using Clickability. We`&apos;ve got a number of new clients on board
          from the platform which has really helped our business grow in the NDIS.&quot;
        </div>
      </Testimonial>
      <SpinnerModal isLoading={isLoading}/>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        centered
        footer={null}
        destroyOnClose
      >
        <SelectedSubscriptionContainer>
          <div className="icon-container">
            <img src={TIER_ICONS[selectedSubscription.tierName]} height="46px" width="46px" alt="subscription-icon"/>
          </div>
          <div className="text">
            <div>{selectedSubscription.billedType}</div>
            <div className="tier-name">{selectedSubscription.tierName}</div>
          </div>
        </SelectedSubscriptionContainer>
        {allowFreeTrial && (
          <FreeTrialContainer>
            <div className="due">Due Today</div>
            <div className="amount">$0.00</div>
            <div className="text">
              <strong>Please add your billing details to activate the FREE 30 DAY TRIAL</strong>
              <div>Your account will not be billed until the end of the trial and you can cancel at any time.</div>
            </div>
          </FreeTrialContainer>
        )}
        {providerUuid ? (
          <CardPayment
            providerUuid={providerUuid}
            onComplete={handleComplete}
            onError={setSignupError}
            anonymous={true}
            buttonLabel={providerUuid ? 'Subscribe' : 'Join Clickability'}
            footer={plan && (
              <PlanSummary
                plan={plan}
                discountedPrice={plan?.id === offer.planId && offer.discountedAmountInclGst}
                allowFreeTrial={allowFreeTrial}
              />
            )}
            busy={busy}
          />
        ) : (
          <CardPaymentHosted
            onComplete={handleComplete}
            isLoading={busy}
            clientToken={clientToken}
            customerId={customerId}
            tokenError={error}
            onError={setSignupError}
            trackingEmail={trackingEmail}
            signupError={signupError}
            footer={plan && (
              <PlanSummary
                plan={plan}
                discountedPrice={plan?.id === offer.planId && offer.discountedAmountInclGst}
                allowFreeTrial={allowFreeTrial}
              />
            )}
          />
        )}
      </Modal>
    </Container>
  );
};

Subscription.propTypes = {
  onComplete: PropTypes.func,
  busy: PropTypes.bool,
  signupError: PropTypes.string,
  setSignupError: PropTypes.func,
  providerUuid: PropTypes.string,
  showSpecialTier: PropTypes.bool,
  buttonText: PropTypes.string,
  allowFreeTrial: PropTypes.bool,
  offer: PropTypes.object,
  trackingEmail: PropTypes.string,
};


export default Subscription;
